import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


import promo1pdf from "../../static/promo1.pdf"
import promo1img from "../../static/challenger-solo-boiler.png"

const PromotionsPage = () => (
  <Layout>
    <SEO title="HSCI — Promotions" />
    <h1>Promotions</h1>
    <p>Check back soon for offers and promotions.</p>
    {/* <ul id={`promos`}>
      <li className="promo" id="promo1">
        <div className="promo-image">
          <img src={promo1img} alt="Imae of challenger CCRKIT202 and challenger boiler"/>
        </div>
        <div className="promo-text">
          <h2><strong>FREE</strong> Piping Kit &amp; Pump!</h2>
          <div className="">Receive a FREE CCRKIT202 Primary / Secondary Piping Kit including Grundfos UPS 15-58 Pump
            with every Challenger Boiler
          </div>
          <ul className="links-list">
            <li className="clearfix"><a href={promo1pdf} target="_blank"><span className="icon link download black"/>Download PDF</a></li>
          </ul>

        </div>

      </li>
      <li>
        <p className="disclaimer">Some conditions apply. Please <a href="/contact">contact Hydronic Systems</a> for more
          information. Promotions and offers are subject to change without prior notice.</p>
      </li>
    </ul> */}


  </Layout>
)

export default PromotionsPage
